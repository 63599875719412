import React from 'react';
import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import './DaySchedule.module.less';
export function CategoryFilter(props) {
    return (React.createElement(ToggleButtonGroup, { className: "dashboard-category-filter", type: "radio", name: "categoryFilter", justified: true, value: props.value, onChange: props.onChange },
        React.createElement(ToggleButton, { className: "filter-toggle-button", value: "ALL" }, "Wszystkie"),
        React.createElement(ToggleButton, { className: "filter-toggle-button", value: "LEADS" }, "Leady"),
        React.createElement(ToggleButton, { className: "filter-toggle-button", value: "CONSENTS" }, "Zgody"),
        React.createElement(ToggleButton, { className: "filter-toggle-button", value: "SCHEDULED" }, "Zaplanowane"),
        React.createElement(ToggleButton, { className: "filter-toggle-button", value: "CARS" }, "Samochody"),
        React.createElement(ToggleButton, { className: "filter-toggle-button", value: "APPLICATIONS" }, "Wnioski"),
        React.createElement(ToggleButton, { className: "filter-toggle-button", value: "DRS_DISCOUNTS" }, "Rabaty DRS"),
        React.createElement(ToggleButton, { className: "filter-toggle-button", value: "VCP_DISCOUNTS" }, "Rabaty VCP"),
        React.createElement(ToggleButton, { className: "filter-toggle-button", value: "INVOICES" }, "Faktury"),
        React.createElement(ToggleButton, { className: "filter-toggle-button", value: "MARKETING" }, "Marketing")));
}
