import React, { useContext, useEffect, useRef } from 'react';
import moment from 'moment';
import timeGridPlugin from '@fullcalendar/timegrid';
import FullCalendar from '@fullcalendar/react';
import plLocale from '@fullcalendar/core/locales/pl';
import './WeekCalendar.module.less';
import { renderEventContent } from '../calendar-events';
import { useTasks } from '../useTasks';
import { DashboardContext } from '../Dashboard.component';
export function WeekCalendar(props) {
    var tasks = useTasks();
    var dashboardContext = useContext(DashboardContext);
    var calendarRef = useRef();
    useEffect(function () {
        tasks.setRawTasks(props.tasks);
    }, [props.tasks]);
    useEffect(function () {
        tasks.filters.setPhrase(props.filters.phrase);
    }, [props.filters]);
    function nextWeek() {
        applyDateFilter(moment(tasks.filters.date.to).add(1, 'week'));
    }
    function previousWeek() {
        applyDateFilter(moment(tasks.filters.date.to).subtract(1, 'week'));
    }
    function currentWeek() {
        applyDateFilter(moment());
    }
    function applyDateFilter(newDate) {
        calendarRef.current.getApi().gotoDate(moment(newDate).toISOString());
        var newFilter = moment(newDate).isSame(moment(), 'week') ?
            { from: '1900-01-01', to: newDate } :
            { from: newDate, to: newDate };
        tasks.filters.setDate(newFilter);
    }
    function applyPhraseFilter(phrase) {
        tasks.filters.setPhrase(phrase);
    }
    return (React.createElement("div", { className: "week-calendar" },
        React.createElement("section", { className: "week-calendar__header" },
            React.createElement("button", { type: "button", className: "btn btn-default fas fa-chevron-left", onClick: previousWeek, "data-testid": "previousWeekButton" }),
            React.createElement("button", { type: "button", className: "btn btn-default current-btn", onClick: currentWeek, "data-testid": "currentWeekButton" }, "Bie\u017C\u0105cy tydzie\u0144"),
            React.createElement("button", { type: "button", className: "btn btn-default fas fa-chevron-right next-button", onClick: nextWeek, "data-testid": "nextWeekButton" }),
            React.createElement("span", { className: "phrase-filter" },
                React.createElement("input", { className: "form-control", type: "text", placeholder: "imi\u0119, nazwisko", "data-testid": "weekCalendarTextFilter", defaultValue: tasks.filters.phrase, onChange: function (event) { return applyPhraseFilter(event.currentTarget.value); } }))),
        React.createElement("section", { className: "week-calendar__body body" },
            React.createElement("div", { className: "body__calendar calendar" },
                React.createElement(FullCalendar, { allDaySlot: false, contentHeight: 600, ref: calendarRef, slotLabelFormat: function (time) {
                        return moment(time.date).format('H');
                    }, header: false, locale: plLocale, defaultDate: moment().toISOString(), plugins: [timeGridPlugin], defaultView: "timeGridWeek", businessHours: {
                        start: '9:00',
                        end: '17:00',
                        daysOfWeek: [1, 2, 3, 4, 5, 6],
                    }, scrollTime: "08:30:00", events: tasks.weekCalendarTasks, eventRender: function (event) { return renderEventContent(event, dashboardContext.previewMode); } })))));
}
