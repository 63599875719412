var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import * as _ from 'lodash';
import { components } from 'react-select';
import OuterAsyncSelect from 'react-select/async';
import './SearchingSelect.module.less';
var Option = function (props) {
    return (React.createElement(components.Option, __assign({}, props),
        React.createElement("div", { "data-testid": "option-" + props.selectProps.getOptionLabel(props.data), className: 'option' },
            "\u00A0",
            props.children)));
};
var ValueContainer = function (props) {
    var placeholderOrLabels = props.children[0];
    return (React.createElement("div", { style: { overflow: 'auto', display: 'flex', padding: '0 4px', flexBasis: 0, flexGrow: 1 } },
        React.createElement("span", { style: { overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' } }, placeholderOrLabels instanceof Array ?
            _.map(placeholderOrLabels, function ($) { return props.selectProps.getOptionLabel($.props.data); }).join(', ') :
            placeholderOrLabels),
        React.createElement("span", { style: { flexShrink: 0 } }, props.children.slice(props.children.length - 1))));
};
export default function SearchingSelect(props) {
    var loadOptions = function (inputValue, callback) {
        if (inputValue.length > 2) {
            setTimeout(function () {
                Promise.resolve(props.loadOptions(inputValue))
                    .then(function (values) { return callback(values); });
            }, 100);
        }
    };
    return (React.createElement(OuterAsyncSelect, __assign({}, props, { className: "searching-select", value: props.value, hideSelectedOptions: false, closeMenuOnSelect: true, onChange: props.onChange, loadOptions: loadOptions, placeholder: props.placeholder || 'Wybierz', noOptionsMessage: function () { return 'Brak rezultatów'; }, loadingMessage: function () { return 'Trwa ładowanie...'; }, components: { Option: Option, ValueContainer: ValueContainer }, isClearable: false, isSearchable: true, getOptionLabel: function ($) { return props.getOptionLabel($) || $.label; }, getOptionValue: function ($) { return props.getOptionValue($) || $.value; }, isMulti: false, theme: function (theme) {
            return (__assign(__assign({}, theme), { borderRadius: 0, colors: __assign(__assign({}, theme.colors), { primary25: '#f5f5f5', primary: 'transparent', color: 'black' }) }));
        }, styles: {
            option: function (provided, state) { return (__assign(__assign({}, provided), { backgroundColor: state.isFocused ? '#f5f5f5' : undefined, color: '#333' })); },
            dropdownIndicator: function (provided) { return (__assign(__assign({}, provided), { padding: '6px' })); },
            control: function (provided) { return (__assign(__assign({}, provided), { '&:hover': {
                    borderColor: 'A'
                }, minHeight: '35px', borderColor: '#ccc' })); },
            container: function (provided) { return (__assign(__assign({}, provided), (props.style || []))); }
        } })));
}
