import React from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';
import Root from './root.component';
var lifecycles = singleSpaReact({
    React: React,
    ReactDOM: ReactDOM,
    rootComponent: Root,
    errorBoundary: function (err, info, props) {
        // Customize the root error boundary for your microfrontend here.
        return null;
    },
});
export var bootstrap = lifecycles.bootstrap, mount = lifecycles.mount, unmount = lifecycles.unmount;
