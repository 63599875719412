import { shortPartyDescription } from 'common/party';
import { withCancellation } from 'common/requests-cancellation/requestsCancellation';
import { ActivePartyVcpDiscounts } from 'extra-sale-discounts/activePartyVcpDiscounts';
import { ApplicationData } from 'extra-sale-discounts/applicationData';
import { CarSalePotential } from 'extra-sale-discounts/carSalePotential';
import { PartyData } from 'extra-sale-discounts/partyData';
import { PartyExtraSaleDiscounts } from 'extra-sale-discounts/partyExtraSaleDiscounts';
import { CarNetListPriceSupplement } from 'extra-sale-discounts/submission-form/CarNetListPriceSupplement';
import { useCarNetListPriceSupplementForm } from 'extra-sale-discounts/submission-form/useCarNetListPriceSupplementForm';
import { UserCard } from 'extra-sale-discounts/UserCard';
import { VcpDiscountSettlement } from 'extra-sale-discounts/vcpDiscountSettlement';
import { ViewSection } from 'extra-sale-discounts/ViewSection';
import React from 'react';
import { useParams } from 'react-router-dom';
import './SubmissionForm.module.less';
export var CarNetListPriceSupplementForm = withCancellation(function () {
    var applicationId = useParams().applicationId;
    var _a = useCarNetListPriceSupplementForm(applicationId), applicant = _a.applicant, application = _a.application, partyCars = _a.partyCars, carGrossListPrice = _a.carGrossListPrice, partyActiveConcessions = _a.partyActiveConcessions, settlement = _a.settlement, partyExtraSaleDiscounts = _a.partyExtraSaleDiscounts, carNetListPrice = _a.carNetListPrice, setCarNetListPrice = _a.setCarNetListPrice, attachments = _a.attachments, setAttachments = _a.setAttachments, submitApplication = _a.submitApplication;
    return React.createElement("article", { className: "extra-sale-discount-opinion-form", style: { marginBottom: '20px' } },
        React.createElement(ViewSection, { title: "Wnioskuj\u0105cy" },
            React.createElement(UserCard, { user: applicant })),
        React.createElement(ViewSection, { title: "Zamawiaj\u0105cy" },
            React.createElement(PartyData, { application: application, partyCars: partyCars })),
        React.createElement(ViewSection, { title: "Uzupe\u0142nienie danych na podstawie faktury VCP" },
            React.createElement(CarNetListPriceSupplement, { carNetListPrice: carNetListPrice, setCarNetListPrice: setCarNetListPrice, attachments: attachments, setAttachments: setAttachments })),
        React.createElement(ViewSection, { title: "Potencja\u0142 sprzeda\u017Cowy" },
            React.createElement(CarSalePotential, { application: application })),
        React.createElement(ViewSection, { title: "Rabat DRS" },
            React.createElement(ApplicationData, { application: application, carGrossListPrice: carGrossListPrice, editableApplicationReasons: function () { return false; }, showChangesHistory: true, showDiscountTypes: false })),
        React.createElement(ViewSection, { title: 'Aktywne rabaty VCP dla strony: ' + shortPartyDescription(application === null || application === void 0 ? void 0 : application.party) },
            React.createElement(ActivePartyVcpDiscounts, { partyActiveConcessions: partyActiveConcessions })),
        React.createElement(ViewSection, { title: "Rozliczenie rabatu VCP samochodu: CON " + (application === null || application === void 0 ? void 0 : application.car.commonOrderNumber) },
            React.createElement(VcpDiscountSettlement, { settlement: settlement })),
        React.createElement(ViewSection, { title: "Rabaty DRS dla strony: " + shortPartyDescription(application === null || application === void 0 ? void 0 : application.party) },
            React.createElement(PartyExtraSaleDiscounts, { partyExtraSaleDiscounts: partyExtraSaleDiscounts })),
        React.createElement("div", { className: "form-controls-container" },
            React.createElement("button", { className: "btn btn-sm btn-confirm", onClick: submitApplication }, "Wy\u015Blij do VCP")));
});
