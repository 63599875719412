import React from 'react';
import { DateInput } from 'common/dateInput.component';
import moment from 'moment';
import './periodsList.module.less';
export function PeriodsList(_a) {
    var periods = _a.periods, editPeriod = _a.editPeriod, editedPeriodId = _a.editedPeriodId, applyStartDate = _a.applyStartDate, applyEndDate = _a.applyEndDate, isSuspended = _a.isSuspended;
    function minimumToDate(from, minimumToDate) {
        if (minimumToDate) {
            return moment(from).isAfter(minimumToDate) ? moment(from).toISOString() : moment(minimumToDate).toISOString();
        }
        else {
            return moment(from).toISOString();
        }
    }
    return (React.createElement("ul", { className: "period-list" }, periods.map(function (period, index) {
        return editedPeriodId === period.id ? (React.createElement("li", { key: period.id, className: "period-item editing-period-item" },
            React.createElement("form", { className: "period-to-edit", id: "activity-periods" },
                React.createElement("div", { className: "date" },
                    React.createElement("label", { className: "col-lg-2 control-label" }, "od:"),
                    React.createElement(DateInput, { date: moment(period.from).toISOString(), setDate: function (newDate) { return applyStartDate(index, newDate); }, minimumDate: moment(period.minimumFromDate).toISOString(), disabled: !period.fromEditable, "data-testid": "fromDateInput" })),
                React.createElement("div", { className: "date" },
                    React.createElement("label", { className: "col-lg-2 control-label" }, "do:"),
                    React.createElement(DateInput, { date: period.to ? moment(period.to).toISOString() : moment().toISOString(), setDate: function (newDate) { return applyEndDate(index, newDate); }, minimumDate: minimumToDate(period.from, period.minimumToDate), className: "" + (!period.endOfPeriodDefined ? 'empty-end-date' : ''), disabled: !period.toEditable, "data-testid": "toDateInput" }))))) : (React.createElement("li", { className: "period-item", key: period.id },
            React.createElement("p", null,
                "od: ",
                moment(period.from).format('YYYY-MM-DD')),
            React.createElement("p", null,
                "do:",
                ' ',
                period.endOfPeriodDefined ? moment(period.to).format('YYYY-MM-DD') : 'nie zdefiniowano'),
            !isSuspended && (period.toEditable || period.fromEditable) ? (React.createElement("button", { className: "fas fa-pencil-alt pencil-button", onClick: function () { return editPeriod(period.id); } })) : (React.createElement("button", { className: "fas fa-pencil-alt", disabled: true, style: { opacity: 0, cursor: 'default', border: 'none' } }))));
    })));
}
