import { shortPartyDescription } from 'common/party';
import { withCancellation } from 'common/requests-cancellation/requestsCancellation';
import { ActivePartyVcpDiscounts } from 'extra-sale-discounts/activePartyVcpDiscounts';
import { ApplicationData } from 'extra-sale-discounts/applicationData';
import { CarSalePotential } from 'extra-sale-discounts/carSalePotential';
import { PartyData } from 'extra-sale-discounts/partyData';
import { PartyExtraSaleDiscounts } from 'extra-sale-discounts/partyExtraSaleDiscounts';
import { UserCard } from 'extra-sale-discounts/UserCard';
import { VcpDiscountSettlement } from 'extra-sale-discounts/vcpDiscountSettlement';
import _ from 'lodash';
import React from 'react';
import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { ViewSection } from '../ViewSection';
import './AcceptanceForm.module.less';
import { useAcceptanceForm } from './useAcceptanceForm';
export var AcceptanceForm = withCancellation(function () {
    var _a = useAcceptanceForm(useParams().applicationId), applicant = _a.applicant, application = _a.application, carGrossListPrice = _a.carGrossListPrice, partyActiveConcessions = _a.partyActiveConcessions, partyCars = _a.partyCars, partyExtraSaleDiscounts = _a.partyExtraSaleDiscounts, settlement = _a.settlement, changeApplicationReasonDiscountType = _a.changeApplicationReasonDiscountType, decision = _a.decision;
    function editableApplicationReasons() {
        return (application === null || application === void 0 ? void 0 : application.status) === 'SUBMITTED' ||
            ((application === null || application === void 0 ? void 0 : application.status) === 'TENTATIVELY_ACCEPTED' && _.some(application === null || application === void 0 ? void 0 : application.grants, function (grant) { return grant.decision === 'NEGATIVE'; }));
    }
    return React.createElement("article", { className: "extra-sale-discount-acceptance-form", style: { marginBottom: '20px' } },
        React.createElement(ViewSection, { title: "Wnioskuj\u0105cy", "data-testid": "applicantSection" },
            React.createElement(UserCard, { user: applicant })),
        React.createElement(ViewSection, { title: "Zamawiaj\u0105cy", "data-testid": "partySection" },
            React.createElement(PartyData, { application: application, partyCars: partyCars })),
        React.createElement(ViewSection, { title: "Potencja\u0142 sprzeda\u017Cowy", "data-testid": "carsSalePotentialSection" },
            React.createElement(CarSalePotential, { application: application })),
        React.createElement(ViewSection, { title: "Rabat DRS", "data-testid": "applicationSection" },
            React.createElement(ApplicationData, { application: application, carGrossListPrice: carGrossListPrice, editableApplicationReasons: editableApplicationReasons, changeApplicationReasonDiscountType: changeApplicationReasonDiscountType, showChangesHistory: true, showDiscountTypes: true })),
        React.createElement(ViewSection, { title: 'Aktywne rabaty VCP dla strony: ' + shortPartyDescription(application === null || application === void 0 ? void 0 : application.party), "data-testid": "importerDiscountsSection" },
            React.createElement(ActivePartyVcpDiscounts, { partyActiveConcessions: partyActiveConcessions })),
        React.createElement(ViewSection, { title: "Rozliczenie rabatu VCP samochodu: CON " + (application === null || application === void 0 ? void 0 : application.car.commonOrderNumber), "data-testid": "settlement-section" },
            React.createElement(VcpDiscountSettlement, { settlement: settlement })),
        React.createElement(ViewSection, { title: "Rabaty DRS dla strony: " + shortPartyDescription(application === null || application === void 0 ? void 0 : application.party), "data-testid": "extra-sale-discounts-for-party-section" },
            React.createElement(PartyExtraSaleDiscounts, { partyExtraSaleDiscounts: partyExtraSaleDiscounts })),
        React.createElement(ViewSection, { title: "Decyzja" },
            React.createElement("div", { className: "form-horizontal col-lg-12" },
                React.createElement("div", { className: "form-group col-lg-12" },
                    React.createElement("label", { className: "col-lg-3 control-label" }),
                    React.createElement("div", { className: "col-lg-9" },
                        React.createElement(ToggleButtonGroup, { className: "filter-controls", type: "radio", name: "decision-selector", justified: true, value: decision.selected, onChange: decision.setSelected },
                            decision.isNoInvoice ? React.createElement(ToggleButton, { className: "filter-toggle-button", value: "TENTATIVE_ACCEPTANCE" }, "Wst\u0119pna akceptacja") : null,
                            !decision.acceptanceDisabled ? React.createElement(ToggleButton, { className: "filter-toggle-button", value: "ACCEPTANCE" }, "Akceptacja") : null,
                            !decision.isFinal ? React.createElement(ToggleButton, { className: "filter-toggle-button", value: "RETURN" }, "Do skorygowania") : null,
                            React.createElement(ToggleButton, { className: "filter-toggle-button", value: "REJECTION" }, "Odmowa"))))),
            React.createElement("div", { className: "form-horizontal col-lg-12" },
                React.createElement("div", { className: "form-group col-lg-12" },
                    React.createElement("label", { htmlFor: "decisionComment", className: "col-lg-3 control-label" }, "Komentarz*"),
                    React.createElement("div", { className: "col-lg-9" },
                        React.createElement("textarea", { id: "decisionComment", className: "form-control", style: { resize: 'vertical' }, value: decision.comment, onChange: function (event) { return decision.setComment(event.target.value); } }),
                        React.createElement("span", null, "*pole obowi\u0105zkowe")))),
            React.createElement("div", { className: "form-horizontal col-lg-12" },
                React.createElement("div", { className: "form-controls-container" },
                    React.createElement("button", { className: "btn btn-sm btn-confirm", onClick: decision.submit }, "Wy\u015Blij")))));
});
